<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="login">
      <div class="header">
        <h1>{{ $t("sms_login") }}</h1>
        <p>{{ $t("sms_login_tips") }}</p>
      </div>

      <van-field v-model.trim="state.phone" type="tel" maxlength="11" :placeholder="$t('placeholder_phone_input')"
        class="border-cell align-items-center" :border="false" :disabled="state.realPhone ? true : false">
        <template #left-icon>
          <van-icon :name="icons.mobile" size="24px" />
        </template>
      </van-field>
      <van-field v-model.number="state.smscode" type="number" maxlength="8"
        :placeholder="$t('placeholder_smscode_input')" class="border-cell align-items-center" :border="false">
        <template #left-icon>
          <van-icon :name="icons.shield" size="24px" />
        </template>
        <template #button>
          <van-button v-if="state.remainingTime > 0" style="border-radius: 15px" size="small" disabled plain
            type="primary">{{
              state.smsButtonText }}</van-button>
          <van-button v-else size="small" :disabled="state.phone.length != 11" type="primary"
            style="border-radius: 15px" @click="sendCode">{{
              state.smsButtonText
            }}</van-button>
        </template>
      </van-field>

      <van-field v-if="state.has_invitation_code && !state.realPhone" v-model.trim="state.invitation_code" type="text"
        maxlength="16" label-width="4em" :label="$t('invitation_code')" :disabled="true" />

      <van-cell :border="false">
        <template #value>
          <van-checkbox v-model="state.argee">{{ $t('agree_all') }}</van-checkbox>
        </template>
      </van-cell>

      <van-button type="primary" class="my-3" style="border-radius: 20px" block @click="login">{{
        submitButtonText
        }}</van-button>

      <div class="footer">
        <a href="javascript:void(0);" @click="$router.push({ name: 'Agreement' })">{{ $t('user_agreement') }}</a>
        &amp;
        <a href="javascript:void(0);" @click="$router.push({ name: 'Privacy' })">{{ $t('privacy_policy') }}</a>
      </div>

    </div>
  </van-config-provider>
</template>

<script>
import {
  Row,
  Col,
  Field,
  Checkbox,
  Toast,
  Tab,
  Tabs,
  Notify,
  Image as VanImage,
} from "vant";
import { useStore } from "vuex";
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sendSmsCode } from "@/api/sms.service";
import { PASSWORD_LOGIN } from "@/store/modules/auth";
import { useI18n } from "vue-i18n";

export default {
  name: "Login",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },

  setup() {
    const { t } = useI18n({ useScope: "global" });
    // 暂时取消了密码登录，只用短信验证码登录，但是保留了 activeTab 的逻辑，如果需要密码登录，只需要回复UI部分即可
    const activeTab = ref(1);
    const submitButtonText = ref(t("login"));
    const themeVars = {
      tabsBottomBarColor: "#222",
      checkboxCheckedIconColor: "#222",
      // buttonPrimaryBackgroundColor: "#222",
      // buttonPrimaryBorderColor: "#222",
    };
    watch(
      () => activeTab.value,
      (newTabId) => {
        if (newTabId == 0) {
          submitButtonText.value = t("login");
          state.smscode = "";
        } else {
          submitButtonText.value = t("login_register");
          state.password = "";
        }
      }
    );

    let timer = null;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userCanLogin: false,
      wechatAuthError: null,
      userinfo: { phone: "" },
      realPhone: "", // 微信用户已经绑定过的手机, 此时的 phone 是脱敏显示的
      phone: "",
      password: "",
      smscode: "",
      invitation_code: "",
      has_invitation_code: false,
      argee: false,
      smsButtonText: t("send_sms_code"),
      remainingTime: 0,
    });

    const redoWechatAuth = () => {
      const url = location.href.split("/login")[0];
      window.location.href = `${url}/login?reauth`;
    };

    const checkPhone = () => {
      if (!state.phone) {
        Toast({ message: t("placeholder_phone_input"), position: "bottom" });
        return false;
      }
      if (state.phone.length != 11) {
        Toast({ message: t("phone_number_error"), position: "bottom" });
        return false;
      }
      const reg = /^1(\d){10}$/;
      if (state.realPhone) {
        if (!reg.test(state.realPhone)) {
          Toast({ message: t("phone_number_error"), position: "bottom" });
          return false;
        }
      } else if (!reg.test(state.phone)) {
        Toast({ message: t("phone_number_error"), position: "bottom" });
        return false;
      }
      return true;
    };

    const checkCode = () => {
      if (!state.smscode) {
        Toast({ message: t("placeholder_smscode_input"), position: "bottom" });
        return false;
      }
      if (state.smscode.toString().length < 4) {
        Toast({ message: t("smscode_error"), position: "bottom" });
        return false;
      }
      return true;
    };

    const tickTick = () => {
      // 定时器 90 秒后可重发短信验证码
      state.remainingTime = 90;
      timer = setInterval(() => {
        if (state.remainingTime == 0) {
          state.smsButtonText = t("send_sms_code");
          clearInterval(timer);
        } else {
          state.remainingTime--;
          state.smsButtonText = t("resend_countdown", {seconds: state.remainingTime});
        }
      }, 1000);
    };

    const sendCode = async () => {
      if (!checkPhone()) {
        return;
      }
      try {
        const resp = await sendSmsCode(
          state.realPhone ? state.realPhone : state.phone
        );
        if (resp.result.BizId) {
          tickTick();
          Toast({ message: t("sended"), position: "bottom" });
          state.smscode = "";
        } else {
          Notify({ type: "warning", message: resp.msg });
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Notify({ type: "warning", message: msg ? msg : t("send_failed") });
      }
    };

    const login = async () => {
      if (!state.argee) {
        return Toast({ message: t("need_agreement"), position: "bottom" });
      }
      if (!checkPhone()) {
        return;
      }
      if (activeTab.value == 0) {
        if (!state.password || state.password.length < 8) {
          return Toast({
            message: t("password_error"),
            position: "bottom",
          });
        }
      } else if (activeTab.value == 1) {
        if (!checkCode()) {
          return;
        }
      }
      store
        .dispatch(PASSWORD_LOGIN, {
          phone: state.realPhone ? state.realPhone : state.phone,
          password: state.password,
          code: state.smscode,
          invitation_code: state.invitation_code,
        })
        .then(() => {
          Toast({ message: t("welcome_login"), position: "bottom", duration: 3500 });

          let redirect = window.sessionStorage.getItem("login_redirect_url");
          window.sessionStorage.removeItem("preauth");
          window.sessionStorage.removeItem("login_redirect_url");

          if (redirect) {
            window.location.href = redirect;
          } else {
            redirect = route.query.redirect || "/";
            router.push(decodeURIComponent(redirect));
          }
        })
        .catch((error) => {
          // 返回 401 会删除 preauth, 详见 src/store/modules/auth.js
          Notify({ type: "danger", message: error });
        });
    };

    onMounted(() => {
      const inviteData = JSON.parse(window.localStorage.getItem("invite"));
      if (inviteData && inviteData.code) {
        state.invitation_code = inviteData.code;
        state.has_invitation_code = true;
      }

      state.userinfo = {
        nickname: "用户登录",
        headimgurl: "",
      };
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    const icons = {
      mobile: require("@/assets/images/icons/mobile.svg"),
      shield: require("@/assets/images/icons/shield.svg"),
    };

    return {
      activeTab,
      submitButtonText,
      themeVars,
      state,
      redoWechatAuth,
      sendCode,
      login,
      icons,
    };
  },
};
</script>

<style lang="less" scoped>
.login {
  padding: 0 20px;

  .header {
    padding: 20px 0;
    text-align: center;

    p {
      color: #cdcdcd;
    }
  }

  .border-cell {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .footer {
    text-align: center;
    color: #969799;

    a {
      color: #969799;
    }
  }
}
</style>